import React from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";

import { GlobalSetting, InputType, LayoutScreen, PermissionAccessType } from "../../utilities/GlobalSetting";
import { PermissionAccess, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckValueNA, Delay, GetInputComponent, GetPropIds, SetTempTarget } from "../../utilities/GlobalFunctions";
import { useGlobal } from "../../utilities/GlobalVariables";
import { useAppService } from "../../services/AppService";
import { AlertMode } from "../AlertComponent";

const settingTitle = 'Education Stage';

export default class SettingEducationStage extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        isLoading: false,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,

        List: [],
        IsListLoaded: false,
        TotalRows: 0,
        PageIndex: 0,
        PageSize: 10,
        // OrderBy: 'Name',
        // OrderType: 'ASC',

        Toggle_EditSettingModal: false,
        SettingModal_Create: false,
        SettingModal_Index: -1,
        SettingModal: null,
        SettingModal_Cache: null,
        isUpdating: false,

        Toggle_RemoveSettingModal: false,
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        // const gv = useGlobal.getState();
        // const { uid, organizerId } = GetPropIds(gv.user);
        // this.setState({
        //     PA_View: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.View),
        //     PA_Search: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Search),
        //     PA_Create: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Create),
        //     PA_Update: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Update),
        //     PA_Delete: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Delete),
        //     PA_Upload: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Upload),
        //     PA_Download: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Download),

        //     // PageSize: CheckNumber(localStorage.getItem(`ManageSetting_EducationStage_List_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
        //     PageSize: 99,
        //     isSuperAdmin: useGlobal.getState().isSuperAdmin,
        //     gv: useGlobal.getState(),
        // });
        this.LoadList_ViaApi();
        if (typeof useGlobal.getState().setRefreshListCallbackFn === 'function')
            useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
    }

    //#region === list ===
    //2024.07.24
    CheckPermissions = async () => {
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageEducationStage, PermissionAccessType.Download),

            // PageSize: CheckNumber(localStorage.getItem(`ManageSetting_EducationStage_List_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
            PageSize: 99,
            isSuperAdmin: useGlobal.getState().isSuperAdmin,
            gv: useGlobal.getState(),
        });
        await Delay(0);
    }
    LoadList_ViaApi = async () => {

        await this.CheckPermissions();    //2024.07.24

        // if (this.state.PA_View === false)
        //     return null;

        this.setState({
            isLoading: true,
            List: [],
            TotalRows: 0,
            IsListLoaded: false,
        });
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Quiz/EducationStage/List/${organizerId}/${authorId}`;
        //  Api/LearningCentre/Quiz/EducationStage/List/{organizerId}/{authorId}
        let totalRows = 0;
        let _List = [];

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    if (data.data !== undefined)
                        if (Array.isArray(data.data)) {
                            _List = data.data;
                            totalRows = CheckObjectNumber(data.data, 'totalCount', _List.length);
                        }
                        else {
                            if (this.state.isDevMode)
                                console.log(`${settingTitle} list is empty.`);
                        }
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - setting - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - setting - load list (error)\n' + error.message);
            });

        this.setState({
            isLoading: false,
            List: JSON.parse(JSON.stringify(_List)),
            TotalRows: totalRows,
            IsListLoaded: true,
        }, () => {
            if (this.state.isDevMode) {
                console.log('TotalRows', totalRows);
                console.log('List', JSON.stringify(_List));
            }
        });
    }
    ListComponents = () => {
        let components = [];

        if (this.state.IsListLoaded === false)
            return null;

        if (this.state.List.length === 0)
            return (<tr><td colSpan={15} align='center'>- list is empty -</td></tr>);

        this.state.List.map((data, key) => {
            components.push(<tr key={'tbi_' + key}>
                <td>{this.state.PageIndex + key + 1}</td>
                <td className='left'>{CheckValueNA(data['name'])}</td>
                <td>{CheckValueNA(data['rangeBegin'])}</td>
                <td>{CheckValueNA(data['rangeEnd'])}</td>
                <td>{CheckValueNA(data['displayOrder'])}</td>
                <td>{CheckObjectBoolean(data, 'markAsFailed') ? '✔' : '-'}</td>
                <td>{CheckObjectBoolean(data, 'published') ? '✔' : '-'}</td>
                <td>{
                    CheckObjectNullValue(data, 'remark') === null ? '-' :
                        <Button
                            variant='info'
                            onClick={() => useAppService.getState().setModal(`Remark :: ${CheckValueNA(data['name'])}`, CheckValueNA(data['remark']))}
                        >View</Button>
                }</td>
                <td>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => this.ToggleEditSettingModal(key)}
                    >{this.state.PA_Update ? 'Edit' : 'View'}</button>
                </td>
            </tr>);
            return null;
        });

        return (components);
    }
    //#endregion

    //#region === edit / new ===
    ToggleEditSettingModal = async (index = -1, create = false) => {

        if (create) {
            if (this.state.PA_Create === false) {
                useAppService.getState().setModal(`New ${settingTitle}`, 'Invalid permission.');
                return null;
            }
            else {
                this.setState({
                    // Toggle_EditSettingModal: true,
                    SettingModal_Create: true,
                    SettingModal_Index: -1,
                    SettingModal: this.PopulateSettingModal(),
                    SettingModal_Cache: this.PopulateSettingModal(),
                    isUpdating: false,
                });
                await Delay(0);
                SetTempTarget(this.state.SettingModal);
                await Delay(0);
                this.setState({
                    Toggle_EditSettingModal: true,
                });
                return null;
            }
        }

        if (this.state.PA_Update === false) {
            useAppService.getState().setModal(`Edit ${settingTitle}`, 'Invalid permission.');
            return null;
        }

        //2024.09.27
        if (index < 0) {
            this.setState({ Toggle_EditSettingModal: false });
            await Delay(200);
        }

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        this.setState({
            // Toggle_EditSettingModal: index < 0 ? false : !this.state.Toggle_EditSettingModal,
            SettingModal_Create: false,
            SettingModal_Index: index,
            SettingModal: index < 0 ? null : this.PopulateSettingModal(this.state.List[index], organizerId, authorId),
            SettingModal_Cache: index < 0 ? null : this.PopulateSettingModal(this.state.List[index], organizerId, authorId),
            isUpdating: false,
        });
        await Delay(0);
        SetTempTarget(this.state.SettingModal);

        // this.setState({
        //     Toggle_EditSettingModal: index < 0 ? false : !this.state.Toggle_EditSettingModal,
        // });

        //2024.09.27
        if (index >= 0) {
            await Delay(200);
            this.setState({ Toggle_EditSettingModal: true });
        }

        if (this.state.isDevMode)
            console.log(`ToggleEditSettingModal (${index}) (${create}) =\n ${JSON.stringify(this.state.SettingModal)}`);
    }
    SettingModalComponent = () => {
        let components = [];
        const settingDetails = this.state.SettingModal;
        if (settingDetails !== null) {

            //Name.
            components.push(<div className="form-group" key='s-e-name'>
                <label>Name</label>
                {
                    GetInputComponent(InputType.Text, null,
                        settingDetails, 'name', null, '', this.state.locale,
                        this.UpdateSelectedSettingModal, null, this.state.isLoading)
                }
            </div>);

            //Range (Begin).
            components.push(<div className="form-group" key='s-e-rb'>
                <label>Range (Begin)</label>
                {
                    GetInputComponent(InputType.Number, null,
                        settingDetails, 'rangeBegin', null, '', this.state.locale,
                        this.UpdateSelectedSettingModal, null, this.state.isLoading)
                }
            </div>);

            //Range (End).
            components.push(<div className="form-group" key='s-e-re'>
                <label>Range (End)</label>
                {
                    GetInputComponent(InputType.Number, null,
                        settingDetails, 'rangeEnd', null, '', this.state.locale,
                        this.UpdateSelectedSettingModal, null, this.state.isLoading)
                }
            </div>);

            //Display Order.
            components.push(<div className="form-group" key='s-e-do'>
                <label>Display Order</label>
                {
                    GetInputComponent(InputType.Number, null,
                        settingDetails, 'displayOrder', null, '', this.state.locale,
                        this.UpdateSelectedSettingModal, null, this.state.isLoading)
                }
            </div>);

            //MarkAsFailed.
            components.push(<div className="form-group" key='s-e-mf'>
                <label>Mark As Failed</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                settingDetails, 'markAsFailed', null, '', this.state.locale,
                                this.UpdateSelectedSettingModal, null, this.state.isLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);

            //Remark.
            components.push(<div className="form-group" key='s-e-r'>
                <label>Remark</label>
                {
                    GetInputComponent(InputType.Text, null,
                        settingDetails, 'remark', null, '', this.state.locale,
                        this.UpdateSelectedSettingModal, null, this.state.isLoading)
                }
            </div>);

            //Published.
            components.push(<div className="form-group" key='s-e-p'>
                <label>Published</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                settingDetails, 'published', null, '', this.state.locale,
                                this.UpdateSelectedSettingModal, null, this.state.isLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);

        }
        return (components);
    }
    UpdateSelectedSettingModal = (modal = null) => {
        if (modal === null)
            return null;
        this.setState({
            SettingModal: this.PopulateSettingModal(modal, CheckObjectNumber(modal, 'organizerId'), CheckObjectNumber(modal, 'authorId')),
        });
    }
    ResetSetting = () => {
        this.setState({
            SettingModal: this.state.SettingModal_Cache,
        });
    }
    PopulateSettingModal = (modal = null, organizerId = 0, authorId = 0) => {
        return {
            id: CheckObjectNumber(modal, 'id'),
            organizerId,
            authorId,
            name: CheckObjectStringEmpty(modal, 'name'),
            remark: CheckObjectStringEmpty(modal, 'remark'),
            rangeBegin: CheckObjectNumber(modal, 'rangeBegin'),
            rangeEnd: CheckObjectNumber(modal, 'rangeEnd'),
            markAsFailed: CheckObjectBoolean(modal, 'markAsFailed'),
            displayOrder: CheckObjectNumber(modal, 'displayOrder'),
            published: CheckObjectBoolean(modal, 'published'),
        };
    }
    SettingModalValidation = (postData = null) => {
        if (postData === null)
            return { success: false, message: 'invalid modal.' };

        let messages = [];

        if (CheckObjectNullValue(postData, 'name') === null)
            messages.push('Name must not be empty.');

        return { success: messages.length === 0, message: messages.join('<br />') };
    }
    AddSetting_ViaApi = async () => {
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', 'Creating setting...', null, AlertMode.Loading);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/EducationStage/Add';

        const postData = this.PopulateSettingModal(this.state.SettingModal, organizerId, authorId);
        if (this.state.isDevMode)
            console.log('AddSetting_ViaApi (postData) =\n' + JSON.stringify(postData));

        const { success: validation_success, message: validation_message } = this.SettingModalValidation(postData);
        if (validation_success === false) {
            useAppService.getState().setModal('Validation Failed', validation_message);
            this.setState({
                isUpdating: false,
            });
            return null;
        }

        let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('AddSetting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - create (error)\n' + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.ToggleEditSettingModal();
            await Delay(300);
            useAppService.getState().setModal();
            if (data !== null) {
                const findIndex = this.state.List.findIndex(x => x.id === data.id);
                if (findIndex > -1)
                    this.ToggleEditSettingModal(findIndex);
            }
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle} has been created.`);
        }
        else {
            useAppService.getState().setModal('', 'Failed to create setting.<br /><br />' + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    UpdateSetting_ViaApi = async () => {
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', 'Updating setting...', null, AlertMode.Loading);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/EducationStage/Update';

        const postData = this.PopulateSettingModal(this.state.SettingModal, organizerId, authorId);
        if (this.state.isDevMode)
            console.log('UpdateSetting_ViaApi (postData) =\n' + JSON.stringify(postData));

        const { success: validation_success, message: validation_message } = this.SettingModalValidation(postData);
        if (validation_success === false) {
            useAppService.getState().setModal('Validation Failed', validation_message);
            this.setState({
                isUpdating: false,
            });
            return null;
        }

        let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('UpdateSetting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - update (error)\n' + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.ToggleEditSettingModal();
            await Delay(300);
            useAppService.getState().setModal();
            if (data !== null) {
                const findIndex = this.state.List.findIndex(x => x.id === data.id);
                if (findIndex > -1)
                    this.ToggleEditSettingModal(findIndex);
            }
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle} has been updated.`);
        }
        else {
            useAppService.getState().setModal('', 'Failed to update setting.<br /><br />' + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    //#endregion

    //#region === remove ===
    ToggleRemoveSettingModal = (modal = null) => {
        if (this.state.PA_Delete === false) {
            useAppService.getState().setModal('', 'Invalid permission.');
            return null;
        }

        this.setState({
            Toggle_RemoveSettingModal: modal === null ? false : !this.state.Toggle_RemoveSettingModal,
            isUpdating: false,
        });
    }
    RemoveSetting_ViaApi = async () => {

        if (this.state.PA_Delete === false) {
            useAppService.getState().setModal('', 'Invalid permission.');
            return null;
        }

        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', 'Removing setting...', null, AlertMode.Loading);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Quiz/EducationStage/Remove/${organizerId}/${authorId}/${CheckObjectNumber(this.state.SettingModal, 'id')}`;

        let success = false;
        await fetch(url,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                success = data.success;
                if (data.success) {
                    if (this.state.isDevMode)
                        console.log(`${settingTitle} has been deleted.`);
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - delete (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - delete (error)\n' + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.ToggleEditSettingModal();
            this.ToggleRemoveSettingModal();
            useAppService.getState().setModal();
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle} has been removed.`);
        }
        else {
            useAppService.getState().setModal('', 'Failed to remove setting...');
        }
        this.setState({
            isUpdating: false,
        });
    }
    //#endregion

    render = () => {
        return (<>
            <table className="table" style={{ tableLayout: 'fixed', marginBottom: 0 }}>
                <tbody>
                    <tr>
                        <td>
                            <h5>Manage {settingTitle}</h5>
                        </td>
                        <td align="right">
                            <Button variant="primary" onClick={() => this.ToggleEditSettingModal(-1, true)}>Add New</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50'>#</th>
                        <th className="left">Name</th>
                        <th width='115'>Range (Begin)</th>
                        <th width='115'>Range (End)</th>
                        <th width='115'>Display Order</th>
                        <th width='115'>Mark As Failed</th>
                        <th width='100'>Published</th>
                        <th width='75'>Remark</th>
                        <th width='75'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            // <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        // this.state.List.length === 0 ? null :
                        //     PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Setting - Edit / Update - Modal */}
            <Modal show={this.state.Toggle_EditSettingModal}
                onHide={() => this.ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>{this.state.SettingModal_Create ? `New ${settingTitle}` : 'Edit :: '} {CheckObjectStringEmpty(this.state.SettingModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={() => this.ToggleRemoveSettingModal(this.state.SettingModal)}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.isUpdating || this.state.PA_Delete === false}
                    >Remove</Button>
                    <Button variant="secondary" onClick={() => this.ToggleEditSettingModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    {
                        this.state.SettingModal_Create ?
                            <Button variant="primary"
                                onClick={() => this.AddSetting_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Create === false}
                            >Create</Button>
                            :
                            <Button variant="primary"
                                onClick={() => this.UpdateSetting_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Update === false}
                            >Update</Button>
                    }
                </Modal.Footer>
            </Modal >

            {/* Setting - Remove (Confirm) - Modal */}
            <Modal show={this.state.Toggle_RemoveSettingModal}
                onHide={() => this.ToggleRemoveSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Remove :: {CheckObjectStringEmpty(this.state.SettingModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you sure you want to remove this {settingTitle.toLowerCase()} ?</p>
                    <p>If so, click confirm to continue.</p>
                    <p><i><b>Caution:</b> Once removed, {settingTitle.toLowerCase()} will not be able to restore.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleRemoveSettingModal()}>Cancel</Button>
                    <Button variant="primary"
                        onClick={() => this.RemoveSetting_ViaApi()}
                        disabled={this.state.isUpdating}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal >
        </>);
    }
}