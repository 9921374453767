import React, { createRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom/cjs/react-router-dom';
// import logo from './logo.svg';
import './App.css';

import { firestore } from './utilities/Firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { useAppService } from './services/AppService';
import { useGlobal } from './utilities/GlobalVariables';
import { CheckBoolean, CheckNullValue } from './utilities/GlobalFunctions';
import { Menu } from './utilities/GlobalSetting';

import AlertModal from './components/AlertComponent';
import LoginScreen from './components/LoginScreen';
import PageNotExist from './components/PageNotExist';
import MainLayout from './components/MainLayout';
import DashboardScreen from './components/DashboardScreen';
import ManageRoomScreen from './components/ManageRoomScreen';
import ManageQuestionSetScreen from './components/ManageQuestionSetScreen';
import EditQuestionSetScreen from './components/EditQuestionSetScreen';
import ManageStudentProfileScreen from './components/ManageStudentProfileScreen';
import ManageAuthorScreen from './components/ManageAuthorScreen';
import ManageSettingScreen from './components/ManageSettingScreen';
import ManageOrganizerScreen from './components/ManageOrganizerScreen';
import ManageTeacherProfileScreen from './components/ManageTeacherProfileScreen';
import ReportStudentStatisticScreen from './components/Reports/ReportStudentStatisticScreen';
import ReportAbsentStudentScreen from './components/Reports/ReportAbsentStudentScreen';
import ManageReportScreen from './components/ManageReportScreen';



let checkVersion_listenToNode = null;   //2024.09.27
let checkVersion_refreshPage = null;    //2024.09.30

class App extends React.Component {

  state = {
    isDevMode: window.location.href.includes('localhost'),
    alertPool: [],
    currentVersion: 2024101001,
  }

  //#region === Version Checker === 2024.09.27
  checkVersion_subscribe = async () => {
    checkVersion_listenToNode = onSnapshot(doc(firestore, 'LiveQuizVersions', 'Portal'), (doc) => {
      const versionSnapshot = doc.data();
      if (this.state.isDevMode) {
        console.log('checkVersion (listen) ' + JSON.stringify(versionSnapshot));
        // console.log('Version: ' + versionSnapshot['Version']);
      }
      if (
        CheckNullValue(versionSnapshot) !== null
        && 'Version' in versionSnapshot
        && typeof versionSnapshot['Version'] === 'number'
      ) {
        const latestVersion = Number(versionSnapshot['Version']);
        if (latestVersion > 0 && latestVersion > this.state.currentVersion) {
          const confirm = window.confirm('New version available, refreshing webpage.');
          if (confirm)
            window.location.reload();
          else
            if (checkVersion_refreshPage === null)
              this.setInterval_checkVersion_refreshPage();
        }
      }
    });
  }
  checkVersion_unsubscribe = () => {
    if (checkVersion_listenToNode !== null && typeof (checkVersion_listenToNode) === 'function')
      checkVersion_listenToNode();
    if (this.state.isDevMode)
      console.log('checkVersion (unsubscribed)');
  }
  setInterval_checkVersion_refreshPage = () => {
    checkVersion_refreshPage = setInterval(() => {
      const isLoading = CheckBoolean(useGlobal.getState().isLoading);
      if (isLoading === false) {
        window.alert('New version available, refreshing webpage.');
        window.location.reload();
      }
    }, 1000);
  }
  //#endregion

  //#region === Alert Modal(s)
  alertsRefMaxQty = useAppService.getState().alertsRefMaxQty;
  alertsRef = Array(this.alertsRefMaxQty).fill(x => createRef());
  alertModalRef = createRef();
  closeModal = () => {
    if (this.alertModalRef !== null)
      this.alertModalRef.closeModal();
  }
  setModal = (title = null, body = null, buttons = null, extraComponent = null, showProgressBar = false) => {
    if (this.alertModalRef !== null)
      this.alertModalRef.setModal(title, body, buttons, extraComponent, showProgressBar);
  }
  setLoading = (title = null, body = null) => {
    if (this.alertModalRef !== null)
      this.alertModalRef.setLoading(title, body);
  }
  closeAlerts = () => {
    const state = useAppService.getState().decreaseAlertLevelIndex();
    // console.log('closeAlert (App) ' + this.state.alertPool.length + ' ' + state.alertLevelIndex);
    if (this.state.alertPool.length > 0 && state.alertLevelIndex > -1) {
      this.alertsRef[state.alertLevelIndex].closeAlert(state.alertLevelIndex);
      // console.log('closeAlert (App, after) ' + this.state.alertPool.length + ' ' + useAppService.getState().alertLevelIndex);
      if (state.alertLevelIndex === 0) {
        useAppService.getState().setAlertLevelIndex(-1);
        // console.log('closeAlert (App) ' + this.state.alertPool.length + ' ' + useAppService.getState().alertLevelIndex);
      }
    }
  }
  setAlerts = (title = null, body = null, buttons = null, extraComponent = null, fixedLevel = false) => {
    const state = fixedLevel ? useAppService.getState() : useAppService.getState().increaseAlertLevelIndex();
    // console.log('setAlert (App) ' + this.state.alertPool.length + ' ' + state.alertLevelIndex);
    if (this.state.alertPool.length > 0 && state.alertLevelIndex < this.alertsRefMaxQty)
      this.alertsRef[state.alertLevelIndex].setAlert(title, body, buttons, extraComponent);
  }
  setLoadings = (title = null, body = null, fixedLevel = false) => {
    const state = fixedLevel ? useAppService.getState() : useAppService.getState().increaseAlertLevelIndex();
    // console.log('setLoading (App) ' + this.state.alertPool.length + ' ' + state.alertLevelIndex);
    if (this.state.alertPool.length > 0 && state.alertLevelIndex < this.alertsRefMaxQty)
      this.alertsRef[state.alertLevelIndex].setLoading(title, body);
  }
  //#endregion

  componentDidMount = () => {
    if (this.state.isDevMode === false)
      this.checkVersion_subscribe();    //2024.09.27

    //#region === Alert Modal(s) - init.
    let alertPool = [];
    [...Array(this.alertsRefMaxQty)].map((data, key) => {
      return alertPool.push(<AlertModal key={'alert-' + key} ref={el => this.alertsRef[key] = el} />);
    });
    this.setState({
      alertPool: alertPool,
    });
    useAppService.setState({
      alertLevelIndex: -1,
      closeModal: this.closeModal,
      setModal: this.setModal,
      setLoading: this.setLoading,
      closeAlerts: this.closeAlerts,
      setAlerts: this.setAlerts,
      setLoadings: this.setLoadings,
    });
    //#endregion

  }

  componentWillUnmount = () => {
    if (this.state.isDevMode === false) {
      //2024.09.27
      this.checkVersion_unsubscribe();

      //2024.09.30
      if (checkVersion_refreshPage !== null) {
        clearInterval(checkVersion_refreshPage);
        checkVersion_refreshPage = null;
      }

      //done.
    }
  }

  // getPageNotExist = () => {
  //   console.log('getPageNotExist', JSON.stringify(useGlobal.getState().user));
  //   return CheckNullValue(useGlobal.getState().user) === null
  //     ? <PageNotExist />
  //     : <MainLayout>
  //       <PageNotExist />
  //     </MainLayout>;
  //   // return <MainLayout>
  //   //   <PageNotExist />
  //   // </MainLayout>
  // }

  render = () => {
    return (<div style={{}}>
      <Router>
        <Switch>

          <Route exact path={Menu['reports'].Link} key='ManageReportScreen'>
            <MainLayout key='M_ManageReportScreen'>
              <ManageReportScreen key='P_ManageReportScreen' />
            </MainLayout>
          </Route>

          <Route exact path={Menu['settings'].Link} key='ManageSettingScreen'>
            <MainLayout key='M_ManageSettingScreen'>
              <ManageSettingScreen key='P_ManageSettingScreen' />
            </MainLayout>
          </Route>

          <Route exact path={Menu['student'].Link} key='ManageStudentProfileScreen'>
            <MainLayout key='M_ManageStudentProfileScreen'>
              <ManageStudentProfileScreen key='P_ManageStudentProfileScreen' />
            </MainLayout>
          </Route>

          {/* <Route exact path={Menu['studentStatisticReport'].Link} key='ManageStudentStatisticReportScreen'>
            <MainLayout key='M_ManageStudentStatisticReportScreen'>
              <ReportStudentStatisticScreen key='P_ManageStudentStatisticReportScreen' />
            </MainLayout>
          </Route> */}

          {/* <Route exact path={Menu['absentStudentReport'].Link} key='ManageAbsentStudentReportScreen'>
            <MainLayout key='M_ManageAbsentStudentReportScreen'>
              <ReportAbsentStudentScreen key='P_ManageAbsentStudentReportScreen' />
            </MainLayout>
          </Route> */}

          <Route exact path={Menu['teacher'].Link} key='ManageTeacherProfileScreen'>
            <MainLayout key='M_ManageTeacherProfileScreen'>
              <ManageTeacherProfileScreen key='P_ManageTeacherProfileScreen' />
            </MainLayout>
          </Route>

          <Route exact path={Menu['editQuestionSet'].Link} key='EditQuestionSetScreen'>
            <MainLayout key='M_EditQuestionSetScreen'>
              <EditQuestionSetScreen key='P_EditQuestionSetScreen' />
            </MainLayout>
          </Route>

          <Route exact path={Menu['questionSet'].Link} key='ManageQuestionSetScreen'>
            <MainLayout key='M_ManageQuestionSetScreen'>
              <ManageQuestionSetScreen key='P_ManageQuestionSetScreen' />
            </MainLayout>
          </Route>

          <Route exact path={Menu['room'].Link} key='ManageRoomScreen'>
            <MainLayout key='M_ManageRoomScreen'>
              <ManageRoomScreen key='P_ManageRoomScreen' />
            </MainLayout>
          </Route>

          <Route exact path={Menu['dashboard'].Link} key='DashboardScreen'>
            <MainLayout key='M_DashboardScreen'>
              <DashboardScreen key='P_DashboardScreen' />
            </MainLayout>
          </Route>

          <Route exact path="/" key='LoginScreen'>
            <LoginScreen key='P_LoginScreen' />
          </Route>

          <Route exact path={Menu['author'].Link} key='ManageAuthorScreen'>
            <MainLayout key='M_ManageAuthorScreen'>
              <ManageAuthorScreen key='P_ManageAuthorScreen' />
            </MainLayout>
          </Route>

          <Route exact path={Menu['organizer'].Link} key='ManageOrganizerScreen'>
            <MainLayout key='M_ManageOrganizerScreen'>
              <ManageOrganizerScreen key='P_ManageOrganizerScreen' />
            </MainLayout>
          </Route>

          <Route path="*">
            <PageNotExist />
          </Route>

        </Switch>
      </Router>

      {/* <p style={{ color: '#fff', textAlign: 'center' }}>{useAppService.getState().alertLevelIndex}</p> */}
      <AlertModal key={'alert-fixed'} ref={el => this.alertModalRef = el} />
      {this.state.alertPool}
    </div>);
  }
}

export default App;
